import React, { useEffect, useRef, useState } from 'react'
import { ArrowRight, ChevronRight, Circle, CreditCard, Search } from 'react-feather';
import { Helmet } from 'react-helmet';
import Skeleton from 'react-loading-skeleton';
import Pagination from 'react-pagination-bootstrap';
import { useNavigate } from 'react-router-dom';
import { Func } from '../glob/Func';
import Notify from '../glob/Notify';
import "./Style/SearchOrder.css";
function SearchOrder() {
const [core,setCore] = useState({"loading" : true});
const elSearch = useRef();
const [SearchInput,setSearchInput] = useState(); 
const [dataOrder,setDataOrder] = useState();
const [AmrPagi,setAmrPagi] = useState();
const navigate = useNavigate();
useEffect(() => {
    setTimeout(() => {
        setCore({...core,"loading" : false})
        setDataOrder({...dataOrder,"loading" : false})
        cekLogin();
    }, 1000);
    const cekLogin = async() => {
        var cek = await Func.cekLogin();
        if(cek?.status == true){
            setSearchInput(cek.data.no_hp)
            elSearch.current.value = await cek.data.no_hp;
            getOrder(cek.data.no_hp,false);
        }
    }
}, []);
async function gotoPagi(page){
    setAmrPagi({...AmrPagi,"active" : page})
    getOrder('','',page)
}
async function getOrder(data = '',notif =true,page = 1){
    setDataOrder({...dataOrder,"loading" : true})
    try {
        let post = await Func.post('api/History/Search',{
            search: data == "" ? SearchInput : data,
            page : page
        });
        if(post?.status === true){
            setDataOrder({...dataOrder,"data" : post.data,"loading" : false})
        } else {
            if(notif == true){
                Notify({
                    "type" : "error",
                    "pesan" : post?.pesan
                })
            }
            setDataOrder({...dataOrder,"loading" : false,"data" : ""})
        }
    } catch (error) {
        setDataOrder({...dataOrder,"loading" : false})
    }
}
function goToDeteail(sid){
    Func.ScrollUp();
    navigate("/order/detail/"+sid);
}
function makeSkeleton(jumlah=6,height=100,width='100%'){
return (
    <div className='row'>
        {
        [...Array(jumlah)].map((elm,index)=>{
            return(<div className='col-lg-4 col-12 mb-2' key={index}>
                <Skeleton width={width} containerClassName='' height={height}></Skeleton>
            </div>)
        })
        }
    </div>
);
}
  return (
    <div>
    <Helmet>
        <title>Lacak Pemesanan</title>
    </Helmet>
        <div id="search"className='mt-3'>
            {core?.loading == true ? (
                <Skeleton height={100}></Skeleton>
            ) : (
                <div className='d-flex justify-content-center'>
                    <div className='col-lg-6 col-12'>
                        <div className='amr-form'>
                            <input onChange={(e)=> setSearchInput(e.target.value)} ref={elSearch} className='amr-input' required></input>
                            <label onClick={()=> {elSearch.current.focus();}} className="amr-label">Masukan Nomor HP</label>
                            <label className='for-icon'><Search/></label>
                        </div>
                        <div className='text-end mt-2'>
                        {dataOrder?.loading == true ? (
                            <button className='amr-btn btn'>Cari Pesanan <i className="fa-solid fa-spinner amr-loader"></i></button>
                        ) : (
                            <button onClick={()=> getOrder()} className='amr-btn btn'>Cari Pesanan <i className='ms-1 fa-solid fa-search'></i></button>
                        )}
                            
                        </div>
                    </div>
                </div>
            )}
        </div>

        <div className='row' id="search-order" style={{marginTop: "30px"}}>
            {dataOrder?.loading == true ? (
                <div className='col-12'>
                    {makeSkeleton(3)}
                </div>
            ) : dataOrder?.data?.data?.length > 0 ? 
                    (
                        <>
                        {dataOrder.data.data.map((data)=>{
                        var data_srv = JSON.parse(data.data_srv);
                        var data_pay = JSON.parse(data.data_pay);
                        return (
                            <div onClick={()=>goToDeteail(data.sid)}  key={"hisorder-"+data.sid} style={{cursor: "pointer"}} className='col-lg-4 col-12'>
                                <div className='amr-card2'>
                                    <div className='d-flex justify-content-between core'>
                                        <div id='for-gambar'>
                                            <img src={data?.category?.img}></img>
                                        </div>
                                        <div id="for-detail">
                                            <div className='amr-font-1 fw-bold nama hide1'><Circle className='me-2' size={15}/> {data_srv?.layanan}</div>
                                            <div className='amr-font-1 category hide1'><ChevronRight className='me-2' size={15}/>{data?.category?.nama}</div>
                                            <div className='amr-font-1 pay hide1'><CreditCard className='me-2' size={15}/>{data_pay?.nama}</div>
                                            <div className='amr-font-1 tanggal-order'>
                                                {data?.create}
                                            </div>
                                        </div>
                                        <div id="for-status">
                                            <span className='amr-font-1 fw-bold status'>
                                                <span className='badge bg-success'>{data.status}</span>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                        </>
                    )
                : ""
            }
            {dataOrder?.data?.last_page > 1  ? (
                <div className='col-12 mt-3'>
                <Pagination activePage={AmrPagi?.active ?? 1}
                itemsCountPerPage={dataOrder.data.per_page}
                totalItemsCount={dataOrder.data.total}
                pageRangeDisplayed={5}
                nextPageText="Next"
                prevPageText="Prev"
                onChange={(p) => gotoPagi(p)}
                />
            </div>
            ) : ""}
            
        </div>
    </div>
  )
}

export default SearchOrder