import React, { useEffect, useRef, useState } from 'react'
import { Helmet } from 'react-helmet';
import Skeleton from 'react-loading-skeleton';
import { useNavigate } from 'react-router-dom';
import { Func } from '../glob/Func';
import Notify from '../glob/Notify';

function Register() {
    const [core,setCore] = useState({"loading" : false,"preloader" : true})
    const refInput = {
        'nama_d' : useRef(),
        'nama_b' : useRef(),
        'no_hp' : useRef(),
        'password' : useRef(),
        'password_k' : useRef(),
    };
    const [Namad,setNamad] = useState();
    const [Namab,setNamab] = useState();
    const [No_hp,setNohp] = useState();
    const [Password,setPassword] = useState();
    const [PasswordK,setPasswordK] = useState();
    const navigate = useNavigate();
    useEffect(() => {
        setTimeout(() => {
            setCore({...core,"preloader" : false})
        }, 1500);
    }, []);
    async function PostRegister(){
        setCore({...core,"loading" : true})
        try {
            var data = {
                nama : `${Namad} ${Namab}`,
                no_hp : No_hp,
                password : Password,
                password_k : PasswordK
            }
            var post = await Func.post('api/auth/Register',data);
            if(post?.status == true){
                navigate("/verifikasi/"+post.token);
                Notify({
                    "type" : "success",
                    "pesan" : "Langkah Terakhir ,Verifikasi Akun anda"
                })
            } else {
                Notify({
                    "type" : "error",
                    "pesan" : post.pesan
                })
            }
            setCore({...core,"loading" : false})
        } catch (error) {
            setCore({...core,"loading" : false})
        }
    }
  return (
    <div>
        <Helmet>
            <title>Daftarkan Akun anda</title>
        </Helmet>
        <div className='d-flex justify-content-center'>
            <div className="col-lg-6 col-12">
            {core?.preloader == true ? (
                <Skeleton height={300}></Skeleton>
            ) : (
                <div className='amr-card2'>
                    <h5 className='amr-hr amr-font-1 fw-bold'>Bergabung Dengan Kami</h5>
                    <div className='d-flex justify-content-center'>
                        <div className='amr-font-3 text-center mt-1 col-10 mb-2'>Bergabung dengan kami dan dapatkan banyak keuntungan</div>
                    </div>
                    <div className='row mt-3'>
                        <div className='col-6 amr-form'>
                            <input ref={refInput.nama_d} onChange={(e) => {setNamad(e.target.value)}} className='amr-input' required/>
                            <label onClick={()=>refInput.nama_d.current.focus()} className='amr-label'>Nama Depan</label>
                        </div>
                        <div className='col-6 amr-form'>
                            <input ref={refInput.nama_b} onChange={(e) => {setNamab(e.target.value)}} className='amr-input' required/>
                            <label onClick={()=>refInput.nama_b.current.focus()} className='amr-label'> Nama Belakang</label>
                        </div>
                        <div className='col-12 amr-form'>
                            <input ref={refInput.no_hp} onChange={(e) => {setNohp(e.target.value)}} type="number" className='amr-input' required/>
                            <label onClick={()=>refInput.no_hp.current.focus()} className='amr-label'> Nomor Handpone</label>
                        </div>
                        <div className='col-12 amr-form'>
                            <input ref={refInput.password} onChange={(e) => {setPassword(e.target.value)}} type="password" className='amr-input' required/>
                            <label onClick={()=>refInput.password.current.focus()} className='amr-label'> Password</label>
                        </div>
                        <div className='col-12 amr-form'>
                            <input ref={refInput.password_k} onChange={(e) => {setPasswordK(e.target.value)}} type="password" className='amr-input' required/>
                            <label onClick={()=>refInput.password_k.current.focus()} className='amr-label'> Konfirmasi Password</label>
                        </div>
                        <div className='col-12'>
                        {core?.loading == true ? (
                            <button className='btn amr-btn w-100'>Daftar <i className="fa-solid fa-spinner amr-loader"></i></button>
                        ) : (
                            <button onClick={() => PostRegister()} className='btn amr-btn w-100'>Daftar</button>
                        )}
                        </div>

                        <div className='mt-4'>
                            <h5 className='amr-font-1 amr-hr'>Sudah Punya Akun ? <span style={{cursor: "pointer"}} className='ms-1 text-primary' onClick={()=> navigate('/login')}>Login </span> </h5>
                        </div>
                    </div>
                </div>
            )}
            </div>
        </div>
    </div>
  )
}

export default Register