import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "./css/Swipper.css";
import { Keyboard,Autoplay, Pagination,Navigation } from "swiper";
import { useNavigate } from 'react-router-dom';

function Swipper(props) {
  var navigate = useNavigate();
  function redirectTo(url){
    window.location.href = url;
  }
    var playTime = props.playTime ?? 2500;
    var data_img = [];
    if(props.dataImg?.length > 0){
        data_img = props.dataImg;
    }
  return (
    <div className='row'>
      <div className='col-12'>
        <div className='d-flex justify-content-center'>
          <div className='col-lg-10 col-12'>
            <div className='content-swipper'>
                  <Swiper
                effect={"coverflow"}
                loop={true}
                grabCursor={true}
                centeredSlides={true}
                autoplayx={{
                  delay: playTime,
                  disableOnInteraction: false,
                  pauseOnMouseEnter: true
                }}
                keyboard= {
                  {enabled: true,pageUpDown : false}
                }
                coverflowEffect={{
                  rotate: 50,
                  stretch: 0,
                  depth: 100,
                  modifier: 1,
                  slideShadows: true,
                }}
                pagination={true}
                navigation={true}
                modules={[Autoplay,Keyboard, Pagination,Navigation]}
                className="mySwiper"
              >
                    {
                        data_img.map((element,index) => (
                            <SwiperSlide onClick={() => {redirectTo(element.to)}} className='amr-swipper' key={index}>
                              <img src={element.img} className="amr-img-swipper"/>
                            </SwiperSlide>
                        ))
                    }
              </Swiper>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Swipper