import { faWallet } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useRef, useState } from 'react'
import { Modal, Table } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import Skeleton from 'react-loading-skeleton';
import { useNavigate } from 'react-router-dom';
import { Func } from '../../glob/Func';
import Notify from '../../glob/Notify';
import PaginationAMR from '../../glob/PaginationAMR';

function Deposite() {
  const [core,setCore] = useState({"loading" : true});
  const [jumlah,setJumlah] = useState(0);
  const refJum = useRef();
  const CardPay = useRef();
  const [payment,setPayment] = useState([]);
  const [SelectedPayment,SetSelectPay] = useState({});
  const [ViewCatPay,setCatPay] = useState({});
  const [modalKonfirmasi,setModalKonf] = useState({"show" : false});
  const [LoadingPost,setLoadingPost] = useState(false);
  const [user,setUser] = useState();
  const [SearchInput,setSearchInput] = useState(); 
  const [AmrPagi,setAmrPagi] = useState();
  const [dataDeposite,setDataDeposite] = useState();
  const navigate = useNavigate();
  useEffect(() => {
    var timeout = setTimeout(() => {
      setCore({...core,"loading" : false})
    }, 1500);

    const cekLogin = async() => {
      var cek = await Func.cekLogin();
      if(cek.status == true){
        setUser(cek.data)
      } else {
        navigate("/login")
        Notify({
          "type" : "error",
          "pesan" : "Login tidak valid"
        })
      }
    }
    cekLogin();
    getHistory('',false,1);
    return () => {
      clearTimeout(timeout);
    };
  }, []);
  useEffect(() => {
    if((SearchInput?.length ?? 0) == 0){
      getHistory("",false);
    }
    if((SearchInput?.length ?? 0) > 2){
      getHistory(SearchInput,false);
    }
  }, [SearchInput]);
  function btnClick(val){
      setJumlah(val)
      refJum.current.value = val;
      CardPay.current.scrollIntoView();
  }
  function showHidePay(cat){
    if((cat in ViewCatPay)){
        if(ViewCatPay[cat] === "show"){
            setCatPay({...ViewCatPay,[cat] : ""});
        } else {
            setCatPay({...ViewCatPay,[cat] : "show"});
        }
    } else {
        setCatPay({...ViewCatPay,[cat] : "show"});
      }
  }
  async function getPayment(){
    await setPayment({...payment,"loading" : true});
    var post = await Func.post('api/Deposite/getPayment',{
      "jumlah" : jumlah
    });
    if(post.status == true){
      setPayment({...payment,"data" : post.data,"loading" : false})
    } else {
      Notify({
        type : "error",
        "pesan" : post.pesan
      })
      setPayment({...payment,"data" : "","loading" : false})
    }
  }

  async function SelectPayment(id){
    SetSelectPay({id : id});
    var post = await Func.post('api/Deposite/selectPay',{
      "jumlah" : jumlah,
      'id' : id
    });
    if(post.status == true){
      SetSelectPay(post.data)
      setModalKonf({...modalKonfirmasi,"show" : true})
    } else {
      SetSelectPay({})
      Notify({
        type : "error",
        "pesan" : post.pesan
      })
    }
  }

  useEffect(() => {
    if(jumlah >= 100){
      getPayment();
    }
  }, [jumlah]);

  async function getHistory(data = '',notif =true,page = 1){
    setDataDeposite({...dataDeposite,"loading" : true})
    try {
      let post = await Func.post('api/Deposite/getHistory',{
          search: data == "" ? SearchInput : data,
          page : page
      });
      if(post?.status === true){
        setDataDeposite({...dataDeposite,"data" : post.data,"loading" : false})
      } else {
          if(notif == true){
              Notify({
                  "type" : "error",
                  "pesan" : post?.pesan
              })
          }
          setDataDeposite({...dataDeposite,"loading" : false,"data" : ""})
      }
  } catch (error) {
    setDataDeposite({...dataDeposite,"loading" : false})
  }
  }
  async function postDepo(){
    setLoadingPost(true)
    try {
      var post = await Func.post('api/Deposite/post',{
        "jumlah" : jumlah,
        'id' : SelectedPayment?.id
      });
      if(post.status == true){
        Notify({
          "type" : "success",
          "pesan" : "Deposite berhasil dibuat, Silahkan selesaikan pembayaran"
        })
        navigate("/deposite/detail/"+post.sid)
      } else {
        Notify({
          type : "error",
          "pesan" : post.pesan
        })
      }
    } catch (error) {
      
    }
    setLoadingPost(false)
  }

  function cancelKonfirmasi(){
    setModalKonf({...modalKonfirmasi,"show" : false})
    SetSelectPay({})
  }
  return (
    <div id="deposite">
    <Helmet>
        <title>Deposite Saldo</title>
    </Helmet>
      <div className='row mt-4'>
      {core.loading == true ? (
        <Skeleton height={300} containerClassName="col-lg-12 col-12"></Skeleton>
      ) : (
        <>
        <div className='col-lg-6'>
          <div className='amr-card'>
            <span style={{fontSize: "18px"}} className='amr-hr fw-bold text-warning'>Pilih Jumlah Deposite</span>
            <div className='row mt-4'>
              <div className='col-6 mb-2'>
                <button style={{padding: "8px 0px"}} onClick={()=>btnClick(300000)} className={'btn w-100 amr-font-2 '+(jumlah == "300000" ? "amr-btn" : "btn-secondary")}><FontAwesomeIcon icon={faWallet} /> 300.000</button>
              </div>
              <div className='col-6 mb-2'>
                <button style={{padding: "8px 0px"}} onClick={()=>btnClick(500000)} className={'btn w-100 amr-font-2 '+(jumlah == "500000" ? "amr-btn" : "btn-secondary")}><FontAwesomeIcon icon={faWallet} /> 500.000</button>
              </div>
              <div className='col-6 mb-2'>
                <button style={{padding: "8px 0px"}} onClick={()=>btnClick(800000)} className={'btn w-100 amr-font-2 '+(jumlah == "800000" ? "amr-btn" : "btn-secondary")}><FontAwesomeIcon icon={faWallet} /> 800.000</button>
              </div>
              <div className='col-6 mb-2'>
                <button style={{padding: "8px 0px"}} onClick={()=>btnClick(1000000)} className={'btn w-100 amr-font-2 '+(jumlah == "1000000" ? "amr-btn" : "btn-secondary")}><FontAwesomeIcon icon={faWallet} /> 1.000.000</button>
              </div>
              <span style={{fontSize: "18px"}} className='amr-hr fw-bold text-warning'>Atau Masukan Jumlah</span>
              <div className='col-12 amr-form mt-3'>
                 <input type="number" onChange={(e) => setJumlah(e.target.value)} ref={refJum} className='amr-input' required></input>
                 <label onClick={()=> refJum.current.focus()} className='amr-label'>Jumlah</label>
              </div>

              
              <div className="amr-card-content" ref={CardPay} >
                  {payment.loading === true ? (
                      <Skeleton style={{borderRadius: '10px'}} containerClassName='col-lg-12 mb-2' height={250}></Skeleton>
                  ) : payment.data?.length < 1 || !payment.data ? (
                          <div></div>
                      ) : (
                        <>
                        <div className='amr-hr amr-font-2 fw-bold h6 mb-3 mt-3 text-warning'>Pilih Pembayaran</div>
                        {Object.entries(payment.data).map((elm,key) => {
                          var detectShow = ([elm[0]] in ViewCatPay) === true ? (ViewCatPay[elm[0]] === "show" ? "tutup" : "") : "";
                          return(
                              <div key={"pay-cat-"+elm[0]} className="col-12 mb-3">
                                  <div className={"card-payment "+detectShow} data-bs-toggle="collapse" href="#collapseExample" aria-expanded="false">
                                      <div className="nama">{Func.getNamePay(elm[0])}</div>
                                      <div className="payment-content">
                                          <div className="row">
                                              {elm[1].map((payCat) =>{
                                                  return(
                                                      <div onClick={()=> {SelectPayment(payCat.id)}} key={"pay-body"+payCat.id} className="col-lg-6 col-12 col-md-6 col-sm-6 mb-3">
                                                          <div className={"payment-body "+(SelectedPayment?.id === payCat.id ? "amr-selected" : "")}>
                                                              <div className="form-img">
                                                                  <img src={payCat.img} alt=""/>
                                                              </div>
                                                              <div className="note-payment">
                                                                  <div>{payCat.nama}</div>
                                                                  <div>Rp {Func.rp(payCat?.total_harga?.total_bayar ?? 0)}</div>
                                                              </div>
                                                          </div>
                                                      </div>
                                                  )
                                              })}
                                          </div>
                                      </div>
                                  </div>
                                  <div className={"card-payment-body "+detectShow}>
                                      <div className="card-payment-list cursor-pointer" onClick={() => showHidePay(elm[0])}>
                                          <div className="all-img amr-scroll">
                                          {elm[1].map((payCat) =>{
                                              return(
                                                  <img key={"img-key-"+payCat.id} src={payCat.img} alt=""/>
                                              )
                                          })}
                                          </div>
                                          <div className="for-ico"></div>
                                      </div>
                                  </div>
                              </div>
                          )
                      })}
                      </>
                          )
                      }  
                  </div>

            </div>
          </div>
        </div>

        <div className='col-lg-6'>
        <div className='amr-card p-0'>
            <span style={{fontSize: "18px"}} className='amr-font-1 pt-2 pb-1 amr-hr text-warning'>Riwayat Deposite Saldo</span>
            <div className='d-flex justify-content-center'>
              <div className='col-lg-5 pe-1'>
                <input onChange={(e) => setSearchInput(e.target.value)} className='form-control form-control-sm' placeholder='Cari....'></input>
              </div>
            </div>
              <div className='table-responsive amr-scroll' style={{"maxHeight" : "250px"}}>
              <Table className='m-0 table-depo'>
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Jumlah</th>
                    <th>Bayar</th>
                    <th>Saldo</th>
                    <th>Status</th>
                    <th>Tanggal</th>
                  </tr>
                </thead>
                {dataDeposite?.loading == true ? (
                  <tbody>
                    <tr>
                        <td className='p-0 m-0' colSpan={100} rowSpan={100}>
                            <div className='w-100 position-relative'>
                                <Skeleton height={150}></Skeleton>
                            </div>
                        </td>
                    </tr>
                  </tbody>
                ) : (dataDeposite?.data?.data?.length ?? 0) == 0 ? (
                  <tbody>
                    <tr>
                      <td className='amr-font-1 text-center' colSpan={10}>Tidak ada data yang tersedia</td>
                    </tr>
                  </tbody>
                ) : (
                  <tbody>
                    {dataDeposite?.data?.data.map((elm) => {
                      return(
                        <tr style={{cursor:"pointer"}} onClick={()=> navigate("/deposite/detail/"+elm.sid)} className='depo-tr' key={"depo"+elm.sid}>
                          <td className='amr-font-2 text-center'>{elm.id}</td>
                          <td className='amr-font-2 text-center'>{Func.rp(elm.jumlah)}</td>
                          <td className='amr-font-2 text-center'>{Func.rp(elm.bayar)}</td>
                          <td className='amr-font-2 text-center'>{Func.rp(elm.saldo)}</td>
                          <td className='amr-font-2 text-center'>
                            {elm.status == "Unpaid" ? (
                              <span className='badge bg-primary'>{elm.status}</span>
                            ) : elm.status == "Success" ? (
                              <span className='badge bg-success'>{elm.status}</span>
                            ) : (
                              <span className='badge bg-danger'>{elm.status}</span>
                            )}
                            
                          </td>
                          <td className='amr-font-1 last'>{elm.tanggal}</td>
                        </tr>
                      )
                    })}
                  </tbody>
                )}
              </Table>
              </div>
            </div>
            <div className='w-100'>
              <PaginationAMR className="mt-2 text-end" pagi={{
                data: AmrPagi,
                setData : setAmrPagi
              }}
              onChange={getHistory}
              data={{
                per_page : dataDeposite?.data?.per_page,
                total : dataDeposite?.data?.total
              }}/>
            </div>
        </div>
        </>
      )}
      </div>

      {modalKonfirmasi.show === true ? (
        <Modal show={modalKonfirmasi.show} onHide={() => setModalKonf({...modalKonfirmasi,"show" : false})}>
        <Modal.Body style={{"backgroundColor" : "rgb(39, 39, 48)"}}>
            <div className="text-end mb-3 position-absolute" style={{"right": "15px",zIndex : "1"}}>
                <span onClick={() => cancelKonfirmasi()} type="button" data-bs-dismiss="modal" aria-label="Close">
                    <i className="fa-solid fa-xmark" style={{"fontSize": "22px"}}></i>
                </span>
            </div>
            <h5 className="text-center fw-bold amr-font-1">Detail Deposite</h5>
            <div className="content-konfirmasi">
            <div className="judul">Ringkasan Deposite</div>
              <div className="data">
                  <div className="kiri">Saldo Didapatkan</div>
                  <div className="kanan">{Func.rp(jumlah)}</div>
              </div>
              <div className="data">
                  <div className="kiri">Total Bayar</div>
                  <div className="kanan">{Func.rp(SelectedPayment?.total_harga?.total_bayar)}</div>
              </div>
            <div className="judul">Ringkasan Pembayaran</div>
              <div className="data">
                  <div className="kiri">Pembayaran Dipilih</div>
                  <div className="kanan">{(SelectedPayment.nama)}</div>
              </div>
              <div className="data">
                  <div className="kiri">Total Fee</div>
                  <div className="kanan">{Func.rp(SelectedPayment?.total_harga?.total_fee)}</div>
              </div>
              <div className="data">
                  <div className="kiri">Total Bayar</div>
                  <div className="kanan">{Func.rp(SelectedPayment?.total_harga?.total_bayar)}</div>
              </div>

            <div className='text-end mt-4'>
                  <span onClick={() => cancelKonfirmasi()} data-bs-dismiss="modal" className="p-4" style={{"cursor": "pointer"}}>Cancel</span>
                         
                  {LoadingPost === true ? (
                      <button className="btn amr-btn" style={{"borderRadius": "20px"}}>Beli Sekarang
                          <i className="fa-solid fa-spinner amr-loader"></i>
                      </button>
                  ) : (
                      <button onClick={()=> postDepo()} className="btn amr-btn" style={{"borderRadius": "20px"}}>Beli Sekarang
                      <i className="fa-solid fa-arrow-right ms-2"></i>
                      </button>
                  )}
            </div>
            </div>
        </Modal.Body>
      </Modal>
      ): ""}

    </div>
  )
}

export default Deposite