import React, { useEffect, useState } from 'react'
import ReactCodeInput from 'react-code-input';
import Countdown from 'react-countdown';
import { Helmet } from 'react-helmet';
import Skeleton from 'react-loading-skeleton';
import { useNavigate, useParams } from 'react-router-dom'
import { Func } from '../glob/Func';
import Notify from '../glob/Notify';

function Verifikasi() {
    const [core,setCore] = useState({"loading" : true,"btn" : false});
    const {token} = useParams();
    const navigate = useNavigate();
    const [data,setData] = useState();
    const [DataSend,setDataSend] = useState();
    const [Otpnya,setOTP] = useState();
    const style = {
        "fontFamily": "monospace",
        "MozAppearance": "textfield",
        "borderRadius": "6px",
        "border": "1px solid",
        "boxShadow": "0px 0px 10px 0px rgba(0,0,0,.10)",
        "margin": "4px",
        "paddingLeft": "8px",
        "width": "36px",
        "height": "42px",
        "fontSize": "32px",
        "boxSizing": "border-box",
        "color": "white",
        "backgroundColor": "rgb(50, 50, 62)",
        "borderColor": "lightgrey"
      };

    async function cekData(){
        var data = {
            token : token,
            type : "cek"
        }
        var post = await Func.post('api/auth/Konfirmasi',data);
        if(post.status == true){
            setData(post.data);
        } else {
            navigate("/login")
            Notify({
                "type" : "error",
                "pesan" : "Data Tidak Ditemukan"
            })
        }
    }
    async function sendOTP(ss = data){
        setCore({...core,"otp_send" : true})
        var otpData = ss.otp;
        otpData = JSON.parse(otpData);
        var send = false;
        var allow = (otpData?.allow ?? "") == "" ? new Date() : new Date(otpData.allow);
        var now = new Date();
        if(now >= allow){
            send = true;
        }
        setDataSend({...DataSend,"allow" : allow.getTime()});
        try {
            if(send == true){
                var data = {
                    token : token,
                    type : "send"
                }
                var post = await Func.post('api/auth/Konfirmasi',data);
                if(post.status == true){
                    var newAllow = new Date(post.allow);
                    setDataSend({...DataSend,"allow" : newAllow.getTime()});
                    Notify({
                        "type" : "success",
                        "pesan" : "OTP Berhasil dikirim"
                    })
                } else {
                    Notify({
                        "type" : "error",
                        "pesan" : post?.pesan
                    })  
                }
            }
            setCore({...core,"otp_send" : false})
        } catch (error) {
            setCore({...core,"otp_send" : false})
        }
    }
    async function KonfirmasiOTP(){
        setCore({...core,"btn" : true})
        try {
            var data = {
                token : token,
                otp: Otpnya,
                type : "konfirmasi"
            }
            var post = await Func.post('api/auth/Konfirmasi',data);
            if(post.status == true){
                Func.setToken(post.jwt);
                setTimeout(() => {
                    document.location.href = "/";
                  }, 2000);
                Notify({
                    "type" : "success",
                    "pesan" : "Akun berhasil di verifikasi"
                })
            } else {
                Notify({
                    "type" : "error",
                    "pesan" : post?.pesan
                })  
            }
            setCore({...core,"btn" : false})
        } catch (error) {
            setCore({...core,"btn" : false})
        }
    }
    useEffect(() => {
        Func.setRule(true)
        setTimeout(() => {
            setCore({...core,"loading" : false})
            cekData();
        }, 1500);
    }, []);
 
    useEffect(() => {
        if(data?.otp){
            sendOTP(data);
        }
    }, [data]);
    const ForOTPSend = (
        <>
            {core?.otp_send == true ? (
                <span><i className="ms-2 fa-solid fa-spinner amr-loader"></i></span>
            ) : (
                <span onClick={() => sendOTP()} className='text-primary ms-1' style={{cursor : "pointer"}}>Kirim Ulang</span>
            )}
        </>
    );
  return (
    <div>
    <Helmet>
        <title>Verifikasi Akun</title>
    </Helmet>
        <div className='d-flex justify-content-center'>
            <div className='col-lg-6 col-12'>
            {core?.loading == true ? (
                <Skeleton height={300}/>
            ) : (
                <div className='amr-card2'>
                    <h5 className='amr-hr amr-font-1'>Verifikasi Akun anda</h5>
                    <div className='d-flex justify-content-center'>
                    <div className='amr-font-3 text-center mt-1 col-8'>Tinggal Selangkah lagi, Masukan OTP Yang telah dikirim di Whatsapp {data?.no_hp}</div>
                    </div>
                    <div className='row mt-4'>
                        <div className='col-12 text-center'>
                            <div className='amr-font-1'>Bukan Anda ? <span onClick={()=>{navigate("/register")}} className='text-danger' style={{cursor:"pointer"}}>Daftar Ulang</span></div>
                            <ReactCodeInput onChange={(e) => setOTP(e)} type='number' fields={6} inputStyle={style}/>
                            <div className='mt-2'>
                                <div className='amr-font-3 mb-2'> Belum Menerima OTP ? 
                                    {(DataSend?.allow ?? "") !== "" ? DataSend.allow > new Date().getTime() ? (
                                        <Countdown onComplete={() => {setDataSend({...DataSend,"allow" : ""})}} className='ms-2' daysInHours={true} key={"CD-OTP"+DataSend.allow} date={DataSend.allow} />
                                    ) : (
                                        ForOTPSend
                                    )
                                     :
                                    (
                                        ForOTPSend
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className='col-12 mt-4'>
                        {core?.btn == true ? (
                            <button className='btn amr-btn w-100'>Konfirmasi <i className="fa-solid fa-spinner amr-loader"></i></button>
                        ) : (
                            <button onClick={() => KonfirmasiOTP()} className='btn amr-btn w-100'>Konfirmasi</button>
                        )}
                            
                        </div>
                    </div>
                </div>
            )}
            </div>
        </div>
    </div>
  )
}

export default Verifikasi