import React from 'react'
import Pagination from 'react-pagination-bootstrap';

function PaginationAMR(props) {
const {className,pagi,data,onChange} = props
async function gotoPagi(page){
    pagi.setData({...pagi?.data,"active" : page})
    onChange('','',page)
}
  return (
    <div className={className}>
    {(data?.per_page ?? 1) > (data?.total ?? 0) ?(
        <>

        </>
    ) : (
        <Pagination activePage={pagi?.data?.active ?? 1}
                itemsCountPerPage={data.per_page}
                totalItemsCount={data.total}
                pageRangeDisplayed={6}
                nextPageText="Next"
                prevPageText="Prev"
                onChange={(p) => gotoPagi(p)}
                />
    )}
    </div>
  )
}

export default PaginationAMR