import React, { useRef, useState } from 'react'
import { Minus, Plus } from 'react-feather';
import Skeleton from 'react-loading-skeleton';
import "./css/FormInput.css";
function FormInput({data,change}) { 
  if(data){
    data = JSON.parse(data);
    data = Object.entries(data)
  }
  const refJum = useRef();
  const keyDilarang = ["jumlah"];
  return (
    <div>
        {!data ? (
          <Skeleton height={100}/>
        ) : (
          <div className="row">
            {data.map((val) => {
              var key = val[0] ?? "notset";
              var value = val[1];
              var namanya = value.nama;
              var placeholder = value?.placeholder ?? "";
              var for_label = value.required == 'n' ? namanya ?? key : (namanya ?? key)+"*";
              if(keyDilarang.includes(key)){
                return "";
              } else if(value.type == 'input'){
                return (
                  <div className={value["class-form"] ?? ""} key={key}>
                    <label>{for_label}</label>
                    <input placeholder={placeholder} className={value.class} onChange={change} id={key} name={key} autoComplete="off"></input>
                  </div>
                );
              } else if(value.type == 'password'){
                return (
                  <div className={value["class-form"] ?? ""} key={key}>
                    <label>{for_label}</label>
                    <input placeholder={placeholder} type="password" className={value.class} onChange={change} id={key} name={key} autoComplete="off"></input>
                  </div>
                );
              } else if(value.type == 'jumlah'){
                return (
                  <div className={value["class-form"] ?? ""} key={key}>
                    <label>{for_label}</label>
                    <div className='input-jumlah' style={{backgroundColor: "white"}}>
                        <span onClick={(e) => change(e,'min',refJum)}><Minus></Minus></span>
                        <input ref={refJum} onChange={(e) => change(e)} type="number" id={key} name={key} className='form-control'></input>
                        <span onClick={(e) => change(e,'plus',refJum)}><Plus></Plus></span>
                    </div>
                  </div>
                );
              } else if(value.type == 'textarea'){
                return (
                  <div className={value["class-form"] ?? ""} key={key}>
                    <label>{for_label}</label>
                    <textarea placeholder={placeholder} type="password" className={value.class} onChange={change} id={key} name={key} autoComplete="off"></textarea>
                  </div>
                );
              } else if(value.type == 'select'){
                var expl = value['value'].split(/\r?\n/);
                var option = expl.map((elm) => {
                  var expin = elm.split("|");
                  var valnya = expin[0] ?? '';
                  var tampil = expin[1] ?? '';
                    return(
                      <option key={valnya} value={valnya}>{tampil}</option>
                    )
                })
                return (
                  <div className={value["class-form"] ?? ""} key={key}>
                    <label>{for_label}</label>
                    <select type="password" className={value.class} onChange={change} id={key} name={key} autoComplete="off">
                      {option}
                    </select>
                  </div>
                );
              }
            })}
          </div>
        )}
    </div>
  )

}

export default FormInput