import React, { useEffect, useRef, useState } from 'react'
import "./Style/Search.css";
import { Search as SearchICO } from 'react-feather';
import Notify from '../glob/Notify';
import { Func } from '../glob/Func';
import Skeleton from 'react-loading-skeleton';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';

function Search() {
  const elSearch = useRef();
  const [SearchInput,setSearchInput] = useState();
  const [Menu,setMenu] = useState({loading : false,"submit" : false});
  const [FormSearch,setFormSearch] = useState({loading : true});
  const ganti = Func.hover_home;
  const navigate = useNavigate();
  const getMenu = async() => {
    setMenu({...Menu,["loading"] : true});
    try {
      let post = await Func.post('api/Home/getMenu',{
        search: SearchInput ?? "1"
      });
      if(post.status == true){
        setMenu({...Menu,["data"] : post.data,["loading"] : false});
      } else {
        Notify({
          "type" : "error",
          "pesan" : post?.pesan
        })
        setMenu({...Menu,["loading"] : false,"data" : ""});
      }
    } catch (error) {
      setMenu({...Menu,["loading"] : false});
    }
  }
  useEffect(() => {
    if(FormSearch?.loading == false){
      setFormSearch({...FormSearch,['loading'] : true});
    }
    setTimeout(() => {
      setFormSearch({...FormSearch,['loading'] : false});
    }, 1500);
  }, []);
  function makeSkeleton(jumlah=6,height=200,width=200){
    return (
      <div className='amr-g'>
          {
            [...Array(jumlah)].map((elm,index)=>{
              return(<Skeleton key={index} width={width} containerClassName='' height={height}></Skeleton>)
            })
          }
      </div>
    );
  }

  async function redirectPage(slug){
    navigate("/order/"+slug)
  }
  return (
    <div id="search">
    <Helmet>
      <title>Pencarian Game</title>
    </Helmet>
      {FormSearch.loading == true ? (
        <Skeleton height={150}/>
      ) : (
        <div className='d-flex justify-content-center mt-4'>
          <div className='col-lg-8 col-12'>
            <div className='amr-form'>
                <input onKeyUp={(e)=> setSearchInput(e.target.value)} ref={elSearch} className='amr-input' required></input>
                <label onClick={()=> {elSearch.current.focus();}} className="amr-label">Masukan nama game disini</label>
                <label className='for-icon'><SearchICO/></label>
            </div>
            <div className='text-end'>
            {Menu?.loading == true ? (
              <button className='btn amr-btn'>Cari Game <i className="fa-solid fa-spinner amr-loader"></i></button>
            ) : (
              <button onClick={()=>getMenu()} className='btn amr-btn'>Cari Game <i className='ms-1 fa-solid fa-search'></i></button>
            )}
            </div>
          </div>
        </div>
      )}

      <div className='row' style={{marginTop: "60px"}}>
        {Menu.loading == true ? (
          makeSkeleton()
        ) : (
         <>
         {!Menu.data ? (
          <>
          </>
         )
           : (Object.entries(Menu.data).map((elm) => {
                return(
                  <div key={elm[0]}>
                  <div className="col-12 mb-3">
                        <div className="judul-layanan amr-font-1">
                            {elm[0].toUpperCase()}
                        </div>
                    </div>
                    <div className="col-lg-12">
                        <div className="amr-g">
                            { elm[1].map((data)=>{
                              return(
                                <div onClick={() => { redirectPage(data.slug)}} className="core-layanan" key={`menu-${elm[0]}-${data.id}}`}>
                                    <div className="layanan">
                                        <img src={data.img} alt="" className="img-layanan"/>
                                        <div className="detail-layanan">
                                            <img src={ganti} alt="" className="img-detail"/>
                                            <div className="keterangan">
                                                <span className="nama amr-font-2">{data.nama}</span>
                                                <span className="dev amr-font-1">{data.type}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                              )
                            })}
                        </div>
                    </div>
                  </div>
                )
              })
          ) }
         </> 
        )}
      </div>
    </div>
  )
}

export default Search