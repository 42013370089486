import React, { useState,useEffect, useRef } from 'react'
import Skeleton from 'react-loading-skeleton'
import { useNavigate } from 'react-router-dom';
import { Func } from '../glob/Func';
import Notify from '../glob/Notify';
import Swipper from '../glob/Swipper';
import "./Style/Home.css";
import Countdown from 'react-countdown';
import { Tab, Tabs } from 'react-bootstrap';
import { ChevronLeft, ChevronRight, Zap } from 'react-feather';
import { Helmet } from 'react-helmet';

function Home() {
  const [Banner,SetBanner] = useState();
  const [Menu,setMenu] = useState();
  const [Flash,SetFlash] = useState();
  const navigate = useNavigate();
  const ganti = Func.hover_home;
  const flashElm = useRef();
  const getFlash = async() => {
    let post = await Func.post('api/Home/getFlash');
    if(post.status === true){
      if(post.data){
        SetFlash(post.data);
      } else {
        SetFlash({})
      }
    } else {
      let pesan = "Gagal Mengambil data Flash";
      SetFlash();
      Notify({
        "type" : "error",
        "pesan" : pesan,
      })
    }
  }
  useEffect( () => {
    const getBanner = async() => {
      let getBanner = await Func.post('api/Home/getBanner');
      if(getBanner.status === true){
        SetBanner(getBanner.data);
      } else {
        let pesan = "Gagal Mengambil data Banner";
        SetBanner({});
        Notify({
          "type" : "error",
          "pesan" : pesan,
        })
      }
    }

    const getMenu = async() => {
      let post = await Func.post('api/Home/getMenu');
      if(post.status === true){
        setMenu(post.data);
      } else {
        let pesan = "Gagal Mengambil data Menu";
        Notify({
          "type" : "error",
          "pesan" : pesan,
        })
      }
    }
    getBanner();
    getMenu();
    getFlash();
  }, [])

  async function redirectPage(slug,flash = false){
    Func.ScrollUp();
    navigate("/order/"+slug)
    if(flash === true){
      var cLogin = await Func.cekLogin();
      if(cLogin.status === true){
        Notify({
          "type" : "success",
          "pesan" : "Layanan berhasil dipilih"
        })
      } else{
        Notify({
          "type" : "error",
          "pesan" : "Untuk mendapatkan harga flashsale silahkan login terlebih dahulu"
        })
      }
    }
  }

  function makeSkeleton(jumlah=6,height=200,width=200){
    return (
      <div className='amr-g'>
          {
            [...Array(jumlah)].map((elm,index)=>{
              return(<Skeleton key={index} width={width} containerClassName='' height={height}></Skeleton>)
            })
          }
      </div>
    );
  }

  function scrollOver(ref,type = 'right'){
    var scrollStep = 200;
    var content = document.querySelector(ref);
    var current = content.scrollLeft;
    var next;
    if(type == 'right'){
      next = current + scrollStep;
    } else {
      next = current - scrollStep;
    }
    content.scrollLeft = next;
  }
  useEffect(() => {
    var XXss = setTimeout(() => {
      var container = document.querySelector("#order-form");
      var items = document.querySelector(".order-Tabs");
      console.log(container.scrollWidth,items.scrollWidth)
      try {
        var scroll = document.querySelector("#scrollbar-order");
        if(items.scrollWidth > container.scrollWidth){
          scroll.style.display = 'flex';
        } else {
          scroll.style.display = "none";
        }
      } catch (error) {
        console.log(error)
      }
    }, 3000);
    return () => {
      clearTimeout(XXss)
    }
  }, [Menu]);
  return (
    <div>
    <Helmet>
        <title>Halaman Utama</title>
    </Helmet>
      <div id="banner">
        {!Banner ? (
          <Skeleton style={{borderRadius: "15px"}} height={200}></Skeleton>
        ) : (<Swipper dataImg={Banner}></Swipper>)}
      </div>

      <div className='row mb-4'>
          {!Flash ? (
            <Skeleton containerClassName='col-lg-12 mt-3' height={200}></Skeleton>
          ) : !Flash.length > 0 ? (
            <></>
          ) : (
            <div className='col-lg-12 amr-card'>
              <div className='amr-font-1 fw-bold' style={{fontSize : "26px"}}>Flash Sale <Zap className='ms-1 text-warning'></Zap></div>
              <hr/>
              <Tabs className="flash-saleTabs">
              {Flash.map((ex,index) => {
                var TOstart = Func.timeOnly(ex.start);
                //var TOend = Func.timeOnly(ex.end);
                var now = new Date();
                var start = new Date(ex.start);
                var end = new Date(ex.end);
                var kataNya = now > start ? "Berakhir Dalam" : "Dimulai Dalam";
                var classnya = now > start ? "mulai" : "otw";
                var tsFor = classnya === "mulai" ?  ex.end_timestamp : ex.start_timestamp;
                if(now > start && end < now){
                  return false;
                }
                      return(
                        <Tab tabClassName='amr-font-1 for-flashsale' eventKey={"tabflash-"+tsFor} title={TOstart} key={"flashsale-"+tsFor}>
                          <div className='ms-3 mt-4 col-12'>
                            <span className='amr-font-1' style={{fontSize: "23px"}}>{kataNya} :</span> 
                            <Countdown onComplete={()=> setTimeout(()=>{getFlash()},1000)} key={ex.tsFor} className={'amr-countdown amr-font-3 '+classnya} date={tsFor * 1000} />
                          </div>
                          <div className='d-flex justify-content-between p-1'>
                            <div><ChevronLeft cursor={"pointer"} onClick={() => scrollOver("#flash-"+ex.id,"left")}/></div>
                            <div><ChevronRight cursor={"pointer"} onClick={() => scrollOver("#flash-"+ex.id)}/></div>
                          </div>
                          <div ref={flashElm} id={"flash-"+ex.id} className='amr-g-flash p-2 amr-scroll'>
                          {ex.data.map((elm,index)=>{
                            return(
                              <div className='core-flash' onClick={() => redirectPage(elm?.category?.slug+"?selected="+elm?.layanan?.id ?? 0,true)} key={"flash-"+index}>
                                <div className='flash'>
                                  <img src={elm?.category?.img} alt="" className="img-flash"/>
                                  <div className='keterangan'>
                                    <div className='for-layanan'>
                                      <span className='amr-font-1'>{elm?.layanan?.nama ?? ""}{elm?.layanan?.nama ?? ""}</span>
                                    </div>
                                    <div className='harga-flash'>
                                          <div >
                                            <span className='coret amr-font-3'>Rp. {Func.rp(elm?.layanan?.harga_dapat?.harga_coret)}</span>
                                          </div>
                                          <div className='form-asli'>
                                            <span className='asli amr-font-2'>Rp. {Func.rp(elm?.layanan?.harga_dapat?.harga)}</span>
                                          </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )
                          })}
                          </div>
                        </Tab>
                      )
                    })}
                </Tabs>
            </div>
          )}
      </div>

      <div className='row mt-4' id="order-form">
        <div id="scrollbar-order" className='justify-content-between p-1' style={{position: 'relative',"left" : 0,"display" : 'none'}}>
          <div style={{userSelect: "none"}}><ChevronLeft cursor={"pointer"} onClick={() => scrollOver(".order-Tabs","left")} /></div>
          <div style={{userSelect: "none"}}><ChevronRight cursor={"pointer"} onClick={() => scrollOver(".order-Tabs")} /></div>
        </div>
        <Tabs className="order-Tabs amr-scroll">
          {!Menu ? makeSkeleton()
           : (Object.entries(Menu).map((elm) => {
                return(
                  <Tab tabClassName='amr-font-1 for-order' eventKey={"type-"+elm[0]} title={elm[0].toUpperCase()} key={"type-"+elm[0]}>
                    <div className="col-lg-12">
                        <div className="amr-g">
                            { elm[1].map((data)=>{
                              return(
                                <div onClick={() => { redirectPage(data.slug)}} className="core-layanan" key={`menu-${elm[0]}-${data.id}}`}>
                                    <div className="layanan">
                                        <img src={data.img} alt="" className="img-layanan"/>
                                        <div className="detail-layanan">
                                        {/*}
                                            <img src={"ganti"} alt="" className="img-detail"/>
                                            {*/}
                                            <div className="keterangan">
                                                <div className="dev amr-font-1 amr-hr">{data.type.toUpperCase()}</div>
                                                <div className="nama amr-font-2 text-center">{data.nama}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                              )
                            })}
                        </div>
                    </div>
                    </Tab>
                )
              })
          ) }
          </Tabs>
      </div>
    </div>
  )
}

export default Home