import { faCrown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useRef, useState,useEffect } from 'react'
import { Dropdown, DropdownButton, Nav, NavDropdown } from 'react-bootstrap';
import { Menu,Home, LogIn, UserPlus, LogOut, Search, X, Headphones, CreditCard, Edit} from 'react-feather'
import Skeleton from 'react-loading-skeleton';
import { Link, useNavigate } from 'react-router-dom';
import { Func } from '../glob/Func';
import Notify from '../glob/Notify';

function Navbar(props) {
  const [MenuNavbar,SetNavbar] = useState();
  const [scrollDir,setScrollDir] = useState();
  const ElmHeader = useRef();
  const ElmUsDropdown = useRef();
  const ElmNav = useRef();
  const [NavShow,setNavShow] = useState(false);
  const [User,setUser] = useState();
  const [Web,setWeb] = useState();
  const [userDrop,setUserDrop] = useState();
  const navigate = useNavigate();
  const LogoutUser = async() =>{
    setNavShow(false)
    Func.ScrollUp();
    try {
      let post = await Func.post('api/auth/Logout');
      if(post.status === true){
        Func.removeToken();
        setUser("")
        Notify({
          "type" : "success",
          "pesan" : "Anda berhasil logout"
        })
        navigate("/login");
      } else {
        Func.removeToken();
        Notify({
          "type" : "error",
          "pesan" : post.pesan
        })
      }
    setNavShow(true)
    } catch (error) {
      setNavShow(true)
    }
  }

  useEffect(() => {
    const cekLogin = async () => {
      try {
        var login = await Func.cekLogin();
        if(login.status === true){
            setUser(login.data)
        }
        setNavShow(true)
      } catch (error) {
        setNavShow(true)
      }
    }
    cekLogin()
  }, []);
  async function getFromAPP(data){
    try {
      if(data !== ""){
        setNavShow(false);
        setUser(data)
        setTimeout(() => {
          setNavShow(true);
        }, 1000);
      }
    } catch (error) {
      return error;
    }
  }
  useEffect(() => {
    getFromAPP(props?.user?.data)
  }, [props?.user?.data]);

  useEffect(() => {
    if(props?.Web?.data){
        var data = props?.Web?.data
        setWeb(data)
    }
  }, [props?.Web?.data]);

  function ClickMenu(){
    SetNavbar("");
    Func.ScrollUp();
  } 

  function forNavigate(url){
    Func.ScrollUp();
    navigate(url);
  }
  const forAttrDrop = {
    show : (userDrop)
  };
  const view =  (
    <div ref={ElmHeader} id="amr-header" className={scrollDir}>
        <div className={"header "+scrollDir}>
            <div className="header-icon">
                <img onClick={() => {navigate("/")}} src={Web?.logo?.value}></img>
            </div>

            <div className="logo-user">
                <div></div>
            </div>
        </div>

        {NavShow === false ? (
          <Skeleton height={50}></Skeleton>
        ) : (
          <div ref={ElmNav} className={"amr-navbar "+MenuNavbar}>
            <a onClick={() => {hamburgerMenu()}} id="hamburger-menu"><Menu size={30}/></a>
            <div className="menu-item">
              <div id='for-close-hamburger'>
                  <span onClick={() => ClickMenu()} className='close'><X size={25}></X></span>
              </div>
              <Link to="/" onClick={() => ClickMenu()}><Home size={14}/> <span>Beranda</span></Link>
              <Link to="/lacak-pesanan" onClick={() => ClickMenu()}><Search size={14}/> <span>Lacak Pesanan</span></Link>
              {
                !User ? (
                  <>
                    <Link to="/login" onClick={() => ClickMenu()}><LogIn size={14}/> <span>Masuk</span></Link>
                    <Link to="/register" onClick={() => ClickMenu()}><UserPlus size={14}/> <span>Daftar</span></Link>
                  </>
                ) : (
                  <>
                    
                  </>
                )
              }
              <Link to="/kontak-kami" onClick={() => ClickMenu()}><Headphones size={14}/> <span>Contact Us</span></Link>
            </div>

            <div className="navbar-ico">
                <img onClick={() => {forNavigate("/")}} src={Web?.logo?.value}></img>
            </div>

            {!User ? (
            <div className='search-ico'>
              <a style={{cursor: "pointer"}} onClick={() => {forNavigate("/game-search")}}>
                <Search size={30}/>
              </a>
            </div>) : (
            <div className='user-ico' onClick={() => {setUserDrop((userDrop == true ? false : true))}}>
            <span data-bs-toggle="dropdown" className='ico-user amr-font-1'>
              {Func.name2Letters(User?.nama)}
            </span>
            <Dropdown.Menu ref={ElmUsDropdown} align={"end"} {...forAttrDrop}>
              <Dropdown.Item onClick={() => {forNavigate("/profile")}}><Edit className='me-1' size={14}/> <span>Profile</span></Dropdown.Item>
              <Dropdown.Item onClick={() => {forNavigate("/deposite")}}><CreditCard className='me-1' size={14}/> <span>Deposite</span></Dropdown.Item>
              <Dropdown.Item onClick={() => {forNavigate("/membership")}}><FontAwesomeIcon className='me-1' icon={faCrown} /> <span>Membership</span></Dropdown.Item>
              <Dropdown.Item onClick={()=> LogoutUser()}>
                  <LogOut className='me-1' size={14}/> <span>Logout</span>
              </Dropdown.Item>
            </Dropdown.Menu>
            </div>)
            }
        </div>
        )}
    </div>
  )

  useOutsideAlerter(ElmHeader,()=> {
    SetNavbar("");
  });
  useOutsideAlerter(ElmUsDropdown,() => {
    setUserDrop(false)
  })
  function hamburgerMenu(){
   SetNavbar("active");
  }

  function useOutsideAlerter(ref,callback = true) {
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        callback();
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);
  }

  useEffect(() => {
    const threshold = 0;
    let lastScrollY = window.pageYOffset;
    let ticking = false;
  
    const updateScrollDir = () => {
      const scrollY = window.pageYOffset;
      var OffsetNav = (ElmNav.current?.offsetTop ?? 0); 
      if (Math.abs(scrollY - lastScrollY) < threshold) {
        ticking = false;
        return;
      }
      //setScrollDir(scrollY > lastScrollY ? "down" : "up"); //ori up down
      if(scrollY <= 70){//70 karna ukuran height header 70px
        setScrollDir("up");
      } else if(scrollY > (OffsetNav)){
        setScrollDir("down");
      }
      //setScrollDir(scrollY > OffsetNav ? "down" : "up"); //Jika Melebihi offsetnav dia akan down
      lastScrollY = scrollY > 0 ? scrollY : 0;
      ticking = false;
    };
  
    const onScroll = () => {
      if (!ticking) {
        window.requestAnimationFrame(updateScrollDir);
        ticking = true;
      }
    };
  
    window.addEventListener("scroll", onScroll);
  
    return () => window.removeEventListener("scroll", onScroll);
  }, [scrollDir]);

  return view;
}

export default Navbar
