import React, { useEffect, useState, useRef } from 'react'
import Skeleton from 'react-loading-skeleton';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import FormInput from '../glob/FormInput';
import { Func } from '../glob/Func';
import Notify from '../glob/Notify';
import "./Style/Order.css";
import Modal from 'react-bootstrap/Modal';
import { Helmet } from 'react-helmet';
import { Minus, Plus } from 'react-feather';
function Order(props) {
    const {slug} = useParams();
    const [GetQuery] = useSearchParams();
    const navigate = useNavigate();
    const [target,setTarget] = useState({});
    const [layanan,setLayanan] = useState([]);
    const [payment,setPayment] = useState([]);
    const [SelectedSrv,setSrv] = useState();
    const [category,setCat] = useState();
    const [ViewCatPay,setCatPay] = useState({});
    const [SelectedPayment,SetSelectPay] = useState({});
    const [SelectedVoucher,SetSelectVoc] = useState();
    const [Voucher,SetVoc] = useState();
    const [NomorWA,setNomorWa] = useState();
    const [modalTarget,setModalTarget] = useState({"show" : false});
    const [modalKonfirmasi,setModalKonf] = useState({"show" : false});
    const [LoadingPost,setLoadingPost] = useState(false);
    const [note_modal,setNoteModal] = useState();
    const [ModalNote,setModalNote] = useState();
    const RefPayment = useRef();
    const RefTarget = useRef();
    const refJum = useRef();
    const inputWa = useRef();
    function changeInput(e,type = '',ref= ''){
        function forType(e,type,ref){
            try {
                var ed;
                if(type === "plus"){
                    ed = parseInt(target[ref.current.name] ?? 0) + 1;
                } else {
                    ed = parseInt(target[ref.current.name] ?? 0) - 1;
                } 
                if(ed <= 0){
                    ed = 1;
                }
                ref.current.value = ed;
                setTarget({...target,[ref.current.name]: ed});
            } catch (error) {
                setTarget({...target,[ref.current.name]: 0});
            }
        }
        if(type === 'plus' || type === 'min'){
            forType(e,type,ref)
        } else {
            if(e.target.name === "jumlah"){
                var valedit = e.target.value.replace(/-/,"");
                if(valedit < 0){
                    setTarget({...target,[e.target.name]: 1});
                    e.target.value = 1;
                } else {
                    valedit = parseInt(valedit)
                    setTarget({...target,[e.target.name]: valedit});
                    e.target.value = valedit;
                }
            } else {
                setTarget({...target,[e.target.name]: e.target.value});
            }
        }
        
    }

    //Reload Ulang Pembayaran jika Voucher berubah
    useEffect(() => {
        if(SelectedSrv){
            selectedService(SelectedSrv);
        }
        if(SelectedPayment?.id){
            SelectPayment(SelectedPayment?.id)
        }
    }, [SelectedVoucher]);


    //Validasi Voucher
    useEffect(() => {
        if(SelectedVoucher?.code){
            ValidasiVoucher(false);
        }
        return() => {
        }
    }, [SelectedSrv]); // ori SelectedPayment,SelectedSrv

    //Untuk Layanan yang make target jumlah
    useEffect(() => {
        if(SelectedSrv){
            selectedService(SelectedSrv,false);
        }
    }, [target.jumlah]);

    useEffect(() => {
        const getData = async() => {
            let getData = await Func.post('api/Order/getData',{
                "slug" : slug
            });
            if(getData.status === true){
                if(getData.data){
                    setCat(getData.data)
                }
                if(getData.data?.layanan.length > 0){
                    setLayanan(getData.data.layanan)
                    setNoteModal(getData.data?.note_modal)
                    var SelectedID = GetQuery.get("selected");
                    if(SelectedID){
                        await selectedService(SelectedID,false)
                        RefTarget.current.scrollIntoView()
                    }
                }
            } else {
                setTimeout(() => {
                    document.location.href = "/";
                }, 2000);
                Notify({
                    "type" : "error",
                    "pesan" : "Data tidak ditemukan"
                });
            }
        }
        const cekLogin = async() => {
            var cek = await Func.cekLogin();
            if(cek?.status == true){
                inputWa.current.value = cek.data.no_hp;
                setNomorWa(cek.data.no_hp)
            }
        }
        cekLogin();
        getData();
    }, [])

    useEffect(() => {
        if(note_modal){
            try {
                var modal_sg = localStorage.getItem("note_modal");
                try {
                    var modalnya = JSON.parse(modal_sg)
                } catch (error) {
                    var modalnya = {};
                }
                
                var note = JSON.parse(note_modal)
                if(modalnya == null){
                    setModalNote({show:true,body : note.note,'last' : note.tanggal})
                } else {
                    if(!modalnya[category?.slug]){
                        setModalNote({show:true,body : note.note,'last' : note.tanggal})
                    } else{
                        var dateDB = new Date(note.tanggal)
                        var dateStg = new Date(modalnya[category?.slug].last)
                        if(dateDB > dateStg){
                            setModalNote({show:true,body : note.note,'last' : note.tanggal})
                        }
                    }
                }
            } catch (error) {
                console.log("ERR MODAL")
            }
        }
    }, [note_modal]);

    function handleModalNote(){
        if(ModalNote?.ignore == true){
            var modal = localStorage.getItem("note_modal");
            try {
                var modalnya = JSON.parse(modal)
            } catch (error) {
                var modalnya = {};
            }
            var data_modal_note = JSON.parse(note_modal)
            modalnya = {...modalnya,[category?.slug] : {
                last : data_modal_note?.tanggal
            }};
            localStorage.setItem("note_modal",JSON.stringify(modalnya))
            setModalNote({show:false})
        } else {
            setModalNote({show:false})
        }
    }

    async function selectedService(id,toPayment = true){
        await setPayment({...payment,"loading" : true});
        await setSrv(id);
        var post;
        if(SelectedVoucher?.code){
            post = {"id" : id ,"voc" : SelectedVoucher.code,"target" : target}
        }  else {
            post = {"id" : id,"target" : target};
        }
        var getPayment = await Func.post('api/Order/getPayment',post);
        if(getPayment.status === true){
            setPayment({...payment,"data" : getPayment.data,"loading" : false})
            if(toPayment === true){
                RefPayment.current.scrollIntoView();
            }
            
        } else {
            await setSrv("");
            await setPayment({...payment,"loading" : false,"data" : ""});
            Notify({
                "type" : "error",
                "pesan" : getPayment?.pesan ?? "-"
            })
        }
    }

    async function SelectPayment(id){
        SetSelectPay({id : id})
        if(SelectedVoucher?.code){
            var post;
            post = {"id" : SelectedSrv,"pay" : id ,"voc" : SelectedVoucher.code,"target" : target}
        }  else {
            post = {"id" : SelectedSrv,"pay" : id ,"target" : target};
        }
        var getPayment = await Func.post('api/Order/selectPayment',post);
        if(getPayment.status === true){
            SetSelectPay(getPayment.data)
        } else {
            SetSelectPay({})
            Notify({
                "type" : "error",
                "pesan" : "Pembayaran tidak tersedia,coba ulangi lagi atau reload halaman"
            })
        }
    }

    async function ValidasiVoucher(notify = true){
        if(!SelectedSrv){
            Notify({
                "type" : "error",
                "pesan" : "Untuk menggunakan voucher silahkan pilih layanan terlebih dahulu"
            })
            return false;
        }
        var post = {"id" : SelectedSrv,"voc" : Voucher};
        var setVoucher = await Func.post('api/Order/useVoucher',post);
        if(setVoucher.status === true){
            SetSelectVoc(setVoucher.data)
            if(notify === true){
                Notify({
                    "type" : "success",
                    "pesan" : "Voucher Berhasil digunakan,silahkan pilih ulang pembayaran"
                })
            }
        } else {
            removeVoucher(false);
            Notify({
                "type" : "error",
                "pesan" : setVoucher.pesan
            })
        }
    }

    async function removeVoucher(notif = true){
        SetSelectVoc("");
        SetVoc("");
        if(notif === true){
            Notify({
                "type" : "success",
                "pesan" : "Voucher berhasil dilepas,silahkan pilih ulang pembayaran"
            })
        }
    }

    async function ShowModalKonfirmasi(){
        setModalKonf({...modalKonfirmasi,"loading" : true})
        var ValidasiAll = await Func.post('api/Order/ShowKonfirmasi',{
            "slug" : slug,
            "target" : target,
            "srv" : SelectedSrv ?? "",
            "payment" : SelectedPayment?.id,
            "voucher" : SelectedVoucher?.code,
            "no_hp" : NomorWA,
        });
        if(ValidasiAll.status === true){
            setModalKonf({...modalKonfirmasi,"show" : true,"target" : ValidasiAll.target,"loading" : false})
        } else {
            setModalKonf({...modalKonfirmasi,"loading" : false})
            Notify({
                "type" : "error",
                "pesan" : ValidasiAll.pesan
            })
        }
        
    }
    
    async function postOrder(){
        setLoadingPost(true);
        try {
            var post = await Func.post('api/Order/postOrder',{
                "slug" : slug,
                "target" : target,
                "nick" : modalKonfirmasi?.target?.nick?.value ?? "",
                "srv" : SelectedSrv ?? "",
                "payment" : SelectedPayment?.id,
                "voucher" : SelectedVoucher?.code,
                "no_hp" : NomorWA,
            });
            if(post.status === true){
                Notify({
                    "type" : "success",
                    "pesan" : "Pesanan Berhasil dibuat,silahkan selesaikan pembayaran"
                })
                Func.ScrollUp();
                navigate("/order/detail/"+post.sid)
            } else {
                setLoadingPost(false)
                Notify({
                    "type" : "error",
                    "pesan" : post.pesan
                })
            }
        } catch (err) {
            setLoadingPost(false)
        }
    }
    
    function showHidePay(cat){
        if((cat in ViewCatPay)){
            if(ViewCatPay[cat] === "show"){
                setCatPay({...ViewCatPay,[cat] : ""});
            } else {
                setCatPay({...ViewCatPay,[cat] : "show"});
            }
        } else {
            setCatPay({...ViewCatPay,[cat] : "show"});
        }
    }


    function CreateBadge(badges){
        var spbg = badges.split("|");
        var final = spbg.map((elm,index)=>{
            var sp2 = elm.split(",");
            var type = sp2[0] ?? "info";
            var text = sp2[1] ?? "-";
            return (
                <span key={"badge-"+index} className={'me-1 badge bg-'+type}>{text}</span>
            );
        });
        return final;
    }
    const view = (
        <div className='row'>
        <Helmet>
            <title>Pembelian {category?.nama ?? "-"}</title>
        </Helmet>
            {!category ? (
                <Skeleton className='col-12 mt-3 mb-3' height={200}></Skeleton>
            ) : (
                <div className="col-12 mt-3 mb-3">
                <div className="amr-card form-sampul">
                    <img src={category.img_sampul} alt=""></img>
                </div>
            </div>
            )}

            {!category ? (
                <Skeleton containerClassName='col-lg-4 mb-2' height={150}></Skeleton>
            ) : (
                <div className="col-lg-4 mb-2">
                <div className="amr-card position-relative">
                    <div className="gambar-cat">
                        <img className="img-cat" src={category?.img} alt=""></img>
                    </div>

                    <div className="keterangan">
                    {
                        !category.badges ? (
                            <></>
                        ) : (
                            <div className='mt-1 text-center'>
                                {CreateBadge(category.badges)}
                            </div>
                        )
                    }
                        <div className="judul">
                            {category?.nama}
                        </div>
                        <div className="note">
                            {category?.note_cat}
                        </div>
                    </div>
                </div>
            </div>
            )}

            <div className="col-lg-8 mb-2">
                <div className="row">
                    {!category?.form_input ? (
                        <Skeleton containerClassName='col-lg-12 mb-2' height={150}></Skeleton>
                    ) : (
                        <div className="col-lg-12 mb-2">
                        <div className="amr-card">
                            <div className="step">
                                <div className="box">
                                    <span className="judul amr-font-2">1</span>
                                </div>
                                <div className="nama">{category.title_target}  
                                {(category.target_modal ? (
                                    <i onClick={() => setModalTarget({...modalTarget,"show" : true})} style={{cursor: "pointer"}} className="ms-2 fa-solid fa-question text-warning"></i>
                                ) : "")}
                                </div>
                            </div>
                            <div className="amr-card-content" ref={RefTarget}>
                                {category.order == 'stock' ? (
                                    <div className='d-flex justify-content-center'>
                                        <div className={"col-lg-8 text-center"}>
                                            <div className='input-jumlah' style={{backgroundColor: "white"}}>
                                                <span onClick={(e) => changeInput(e,'min',refJum)}><Minus></Minus></span>
                                                <input placeholder='Jumlah' ref={refJum} onChange={(e) => changeInput(e)} type="number" name="jumlah" className='form-control'></input>
                                                <span onClick={(e) => changeInput(e,'plus',refJum)}><Plus></Plus></span>
                                            </div>
                                        </div>
                                    </div>
                                ) : <></>}
                                <FormInput change={changeInput} data={category?.form_input}></FormInput>
                                <div className="col-12 mt-3 text-center text-warning" style={{fontSize: "14px"}}>
                                    {category?.note_target}
                                </div>
                            </div>
                        </div>
                    </div>
                    )}

                    {layanan.length < 1 ? (
                            <Skeleton height={400} containerClassName="col-lg-12 mb-2"></Skeleton>
                        ) : (
                            <div className="col-lg-12 mb-2">
                                <div className="amr-card">
                                    <div className="step">
                                        <div className="box">
                                            <span className="judul amr-font-2">2</span>
                                        </div>
                                        <div className="nama">Pilih Item</div>
                                    </div>
                                    <div className="amr-card-content">
                                        <div className="row">
                                            {layanan.map((item) => {
                                                var classReady = 'ready';
                                                var attr = {
                                                    onClick : () => {selectedService(item.id)}
                                                }
                                                var edit_jumlah = (target?.jumlah ?? 1) == 0 ? 1 : (target?.jumlah ?? 1);
                                                if(category.order === 'stock' && item.stock_count < edit_jumlah){
                                                    classReady = 'not-ready';
                                                    attr = "";
                                                }
                                                return(
                                                    <div {...attr} style={{position: "relative"}} className={"col-lg-4 col-md-4 col-6 mt-2 mb-1 "+classReady} key={"srv-"+item.id}>
                                                        <div className='out-of-stock'>
                                                            <span className='d-flex justify-content-center'>
                                                                Stock Tidak Mencukupi
                                                            </span>
                                                        </div>
                                                        <div className={"amr-item "+ (SelectedSrv == item.id ? "amr-selected" : "")}>
                                                            <div className="nama">
                                                                {item.nama}
                                                            </div>
                                                            <div className="img-srv">
                                                                {item.img_srv ? (
                                                                    <img key={"img-srv-"+item.id} src={item.img_srv} className="img-layanan" alt='img-layanan'/>
                                                                ) : (
                                                                    <>

                                                                    </>
                                                                )}
                                                                
                                                            </div>
                                                            <div className="harga">
                                                                {item?.harga_dapat?.harga_coret ?? "" ? (
                                                                    <div className='d-flex flex-column'>
                                                                        <div className='harga_coret'>Rp {Func.rp(item?.harga_dapat?.harga_coret)}</div>
                                                                        <div>Rp {Func.rp(item?.harga_dapat?.harga)}</div>
                                                                    </div>
                                                                ) : (
                                                                    <>
                                                                        <div>Rp {Func.rp(item?.harga_dapat?.harga)}</div>
                                                                    </>
                                                                )}
                                                                
                                                            </div>
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}


                            <div ref={RefPayment} id="pembayaran" className="col-lg-12 mb-2">
                            <div className="amr-card">
                                <div className="step">
                                    <div className="box">
                                        <span className="judul amr-font-2">3</span>
                                    </div>
                                    <div className="nama">Pilih Pembayaran</div>
                                </div>

                                <div className="amr-card-content">
                                {payment.loading === true ? (
                                    <Skeleton containerClassName='col-lg-12 mb-2' height={150}></Skeleton>
                                ) : payment.data?.length < 1 || !payment.data ? (
                                        <div></div>
                                    ) : (Object.entries(payment.data).map((elm,key) => {
                                        var detectShow = ([elm[0]] in ViewCatPay) === true ? (ViewCatPay[elm[0]] === "show" ? "tutup" : "") : "";
                                        return(
                                            <div key={"pay-cat-"+elm[0]} className="col-12 mb-3">
                                                <div className={"card-payment "+detectShow} data-bs-toggle="collapse" href="#collapseExample" aria-expanded="false">
                                                    <div className="nama">{Func.getNamePay(elm[0])}</div>
                                                    <div className="payment-content">
                                                        <div className="row">
                                                            {elm[1].map((payCat) =>{
                                                                return(
                                                                    <div onClick={()=> {SelectPayment(payCat.id)}} key={"pay-body"+payCat.id} className="col-lg-6 col-12 col-md-6 col-sm-6 mb-3">
                                                                        <div className={"payment-body "+(SelectedPayment?.id === payCat.id ? "amr-selected" : "")}>
                                                                            <div className="form-img">
                                                                                <img src={payCat.img} alt=""/>
                                                                            </div>
                                                                            <div className="note-payment">
                                                                                <div>{payCat.nama}</div>
                                                                                <div>Rp {Func.rp(payCat?.total_harga?.total_bayar ?? 0)}</div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            })}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className={"card-payment-body "+detectShow}>
                                                    <div className="card-payment-list cursor-pointer" onClick={() => showHidePay(elm[0])}>
                                                        <div className="all-img amr-scroll">
                                                        {elm[1].map((payCat) =>{
                                                            return(
                                                                <img key={"img-key-"+payCat.id} src={payCat.img} alt=""/>
                                                            )
                                                        })}
                                                        </div>
                                                        <div className="for-ico"></div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })
                                        )
                                    }  
                                </div>
                            </div>
                        </div>
                          
                        
                        <div className="col-lg-12 mb-2" id="use_voucher">
                                <div className="amr-card">
                                    <div className="step">
                                        <div className="box">
                                            <span className="judul amr-font-2">4</span>
                                        </div>
                                        <div className="nama">Kode Promo</div>
                                    </div>
                                    
                                    <div className="amr-card-content">
                                        <div className="col-lg-12">
                                        {!SelectedVoucher ? (
                                            <div className="input-group mb-3 amr-input">
                                                <i className="fa-solid fa-ticket icon-vc"></i>
                                                <input onChange={(e) =>{SetVoc(e.target.value)}} style={{"borderRadius": "10px 0px 0px 10px"}} type="text" className="form-control" placeholder="Masukan Voucher"/>
                                                <button onClick={()=> {ValidasiVoucher()}} style={{"borderRadius": "0 10px 10px 0"}} className="btn amr-btn" type="button" id="button-addon2">Pakai Voucher</button>
                                            </div>
                                        ) : 
                                        (
                                            <div>
                                                <div className="input-group mb-3 amr-input">
                                                    <i className="fa-solid fa-ticket icon-vc"></i>
                                                    <input disabled value={SelectedVoucher.code} style={{"borderRadius": "10px 0px 0px 10px","cursor" : "not-allowed"}} type="text" className="form-control" placeholder="Masukan Voucher"/>
                                                    <button onClick={() => removeVoucher()} style={{"borderRadius": "0 10px 10px 0"}} className="btn amr-btn-danger " type="button" id="button-addon2">Hapus Voucher</button>
                                                </div>
                                                <small className="text-warning">Berhasil gunakan {SelectedVoucher.nama}</small>
                                            </div>
                                        )}
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div className="col-lg-12 mb-2" id="nomor_wa">
                                <div className="amr-card">
                                    <div className="step">
                                        <div className="box">
                                            <span className="judul amr-font-2">5</span>
                                        </div>
                                        <div className="nama">Nomor Whatsapp </div>
                                    </div>
                                    
                                    <div className="amr-card-content">
                                        <div className="col-lg-12">
                                            <input ref={inputWa} onChange={(e) => {setNomorWa(e.target.value)}} type="text" className="form-control amr-border" placeholder="08xxxxxxxxxx"/>
                                            <small className="text-white">Bukti pembelianmu akan kami kirimkan ke WhatsApp.</small>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-12 mt-1 text-end">
                            {modalKonfirmasi.loading === true ? (
                                <button className="btn amr-btn">
                                    Konfirmasi Pesanan <i className="fa-solid fa-spinner amr-loader"></i>
                                </button>
                            ) : (
                                <button onClick={() => {ShowModalKonfirmasi()}} className="btn amr-btn">
                                    Konfirmasi Pesanan
                                </button>
                            )}
                            </div>
                </div>
            </div>

     {ModalNote?.show === true ? (
        <>
        <Modal centered id="modal-note" size='lg' show={ModalNote.show} onHide={() => setModalNote({...ModalNote,"show" : false})}>
        <Modal.Header style={{"backgroundColor" : "rgb(39, 39, 48)",borderBottom:"0px","padding" : "15px"}}>
            <div className="text-end position-absolute" style={{"right": "15px",zIndex : "1","top" : "17px"}}>
                <span onClick={() => setModalNote({...ModalNote,"show" : false})} type="button" data-bs-dismiss="modal" aria-label="Close">
                    <i className="fa-solid fa-xmark" style={{"fontSize": "22px"}}></i>
                </span>
            </div>
            <div className='d-flex flex-column'>
            <h5 className="fw-bold amr-font-1">INFORMASI</h5>
            <span className='badge bg-warning' style={{fontSize:"11px"}}>
                Update : {ModalNote.last}
            </span>
            </div>
        </Modal.Header>
        <Modal.Body className='note-target-scroll position-relative' style={{"backgroundColor" : "rgb(39, 39, 48)",maxHeight: "300px",overflow: "scroll"}}>
            <div className='content-note' dangerouslySetInnerHTML={{ __html: ModalNote?.body }} />
        </Modal.Body>
        <Modal.Footer className='d-flex justify-content-between p-2' style={{"backgroundColor" : "rgb(39, 39, 48)","border" : 0}}>
            <div className='d-flex align-items-center'>
                <input onChange={(e) => setModalNote({...ModalNote,"ignore" : e.target.checked})} type="checkbox" id="ignore_modal" className='me-2'></input>
                <label htmlFor="ignore_modal">Jangan Tampilkan lagi</label>
            </div>
            <div>
                <button onClick={() => handleModalNote()} className='btn btn-success btn-sm'>Tutup</button>
            </div>
        </Modal.Footer>
        </Modal>
        </>
     ) : ""}

      {modalTarget.show === true ? (
        <Modal show={modalTarget.show} onHide={() => setModalTarget({...modalTarget,"show" : false})}>
            <Modal.Body style={{"backgroundColor" : "rgb(39, 39, 48)",padding : "0px"}}>
                <div className="text-end mb-3 position-absolute" style={{"right": "5px",zIndex : "1"}}>
                    <span onClick={() => setModalTarget({...modalTarget,"show" : false})} type="button" data-bs-dismiss="modal" aria-label="Close">
                        <i className="fa-solid fa-xmark" style={{"fontSize": "25px"}}></i>
                    </span>
                </div>
                <div className='form-note-target'>
                    <img src={category.target_modal} alt="img-cat"></img>
                </div>
            </Modal.Body>
        </Modal>
     ) : ""}

      {modalKonfirmasi.show === true ? (
        <Modal show={modalKonfirmasi.show} onHide={() => setModalKonf({...modalKonfirmasi,"show" : false})}>
        <Modal.Body style={{"backgroundColor" : "rgb(39, 39, 48)"}}>
            <div className="text-end mb-3 position-absolute" style={{"right": "15px",zIndex : "1"}}>
                <span onClick={() => setModalKonf({...modalKonfirmasi,"show" : false})} type="button" data-bs-dismiss="modal" aria-label="Close">
                    <i className="fa-solid fa-xmark" style={{"fontSize": "22px"}}></i>
                </span>
            </div>
            <h5 className="text-center fw-bold amr-font-1">Detail Pesanan</h5>
                <p className="text-center amr-font-2">Jika Data Pesanan Kamu Sudah Benar Klik Beli Sekarang</p>
                <div className="content-konfirmasi">
                    <div className="judul">Data Player</div>
                    {Object.entries(modalKonfirmasi.target).map((elm) => {
                        if(elm[1]?.show === true){
                            return(
                                <div key={elm[0]} className="data">
                                    <div className="kiri">{elm[1].nama}</div>
                                    <div className="kanan">{elm[1].value}</div>
                                </div>
                            )
                        } else {
                            return "";
                        }
                    })}

                    <div className="judul">Ringkasan Pembelian</div>
                    <div className="data">
                        <div className="kiri">Nomor Whatsapp</div>
                        <div className="kanan">{NomorWA}</div>
                    </div>
                    <div className="data">
                        <div className="kiri">Harga</div>
                        <div className="kanan">Rp {Func.rp(SelectedPayment?.total_harga?.harga)}</div>
                    </div>
                    
                    <div className="data">
                        <div className="kiri">Diskon</div>
                        <div className="kanan">Rp {Func.rp(SelectedPayment?.total_harga?.total_diskon ?? 0)}</div>
                    </div>
                    <div className="data">
                        <div className="kiri">Fee</div>
                        <div className="kanan">Rp {Func.rp(SelectedPayment?.total_harga?.total_fee ?? 0)}</div>
                    </div>
                    <div className="data">
                        <div className="kiri">Pembayaran</div>
                        <div className="kanan">{SelectedPayment.nama ?? "-"}</div>
                    </div>
                    <div className="data">
                        <div className="kiri">Total Pembayaran</div>
                        <div className="kanan">Rp {Func.rp(SelectedPayment?.total_harga?.total_bayar)}</div>
                    </div>

                    <div className="col-12 text-end mt-3">
                        <span onClick={() => setModalKonf({...modalKonfirmasi,"show" : false})} data-bs-dismiss="modal" className="p-4" style={{"cursor": "pointer"}}>Cancel</span>
                         
                        {LoadingPost === true ? (
                            <button className="btn amr-btn" style={{"borderRadius": "20px"}}>Beli Sekarang
                                <i className="fa-solid fa-spinner amr-loader"></i>
                            </button>
                        ) : (
                            <button onClick={()=> postOrder()} className="btn amr-btn" style={{"borderRadius": "20px"}}>Beli Sekarang
                            <i className="fa-solid fa-arrow-right ms-2"></i>
                            </button>
                        )}
                    </div>
                </div>
        </Modal.Body>
      </Modal>
      ): ""}

      </div>
    );
    return view;
}

export default Order